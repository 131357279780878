import i18next from 'i18next';
import { authenticateWithAdn } from '../../helpers/adn';
import Dom from '../../helpers/dom-helper';
import DomManager from '../../helpers/dom-manager';
import env from '../../helpers/env';
import EventLogger, { shouldLogError } from '../../helpers/event-logger';
import EventBus from '../../helpers/eventBus';
import { shouldRedirectToUrl, shouldRenderUrlPresentationInLargerIframe } from '../../helpers/helper';
import Http from '../../helpers/http';
import KeepAliveHelper from '../../helpers/keep-alive';
import { Defer, HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX } from '../../helpers/promise';
import Url from '../../helpers/url-helper';
import ConsentModal from '../modal/consent-modal';
import Modal from '../modal/modal';
import PrivacyWarning from '../privacy-warning/privacyWarning';
import Header from './showcase-header';
import ShowcaseTheme from './showcase-theme';
import ShowcaseViewer from './showcase-viewer';

// Http code for document not found
const FALLBACK_RESPONSE_404 = { success: false, status: 404, data: {} };

// Status message when a fetch call was cancelled
const FETCH_CANCELLED_MESSAGE = 'cancelled';
// Default session lifetime
const DEFAULT_SESSION_TTL = 1440;
const DEFAULT_CONSENT_EXPIRY = 14;

/**
 * Decide if response failure should be ignored or not
 * @param response
 * @returns {boolean}
 */
function shouldIgnoreLoadFailure(response) {
    return [404, 410].includes(response.status) || response.message === FETCH_CANCELLED_MESSAGE;
}

export default class ShowcaseClient {
    constructor() {
        this.info = null;
        this.modal = null;
        this.consentModal = null;

        // Prefetch the DOM elements
        this.domManager = new DomManager();
        this.fetchDomElements();

        this.eventBus = new EventBus();

        // Fix touch issue
        if (!Dom.doesBrowserSupportTouch()) {
            Dom.addClass('no-touch', this.domManager.get('body'));
        }

        this.consentDeferPromise = new Defer();
        this.trackShowcaseViewDeferPromise = new Defer();
    }

    /**
     * Prepare setup for showcase and loggers
     * @param {String} baseUrl
     * @param {String} shareHash
     * @param {String} showcaseBaseUrl
     */
    preload(baseUrl, shareHash, showcaseBaseUrl) {
        this.shareHash = shareHash;

        this.prepareDataUrls(baseUrl, shareHash);
        this.storeShareHash(baseUrl, shareHash, showcaseBaseUrl);
    }

    /**
     * Load info about the showcase
     * @param {String} baseUrl
     * @param {String} shareHash
     * @param {String} showcaseBaseUrl
     * @returns {promise}
     */
    load(baseUrl, shareHash, showcaseBaseUrl) {
        const self = this;
        this.setIsShowcaseLoading(true);
        this.preload(baseUrl, shareHash, showcaseBaseUrl);

        const url = Url.getUrlWithParams('showcase-shallow-info');

        this.eventLogger = new EventLogger(shareHash, []);

        return Http.getJson(url)
            .then(response => {
                const consentRequired = response.data.consent && response.data.consent.required && response.data.consent.enabled;
                this.eventLogger = new EventLogger(shareHash, response.data.trackEvents, consentRequired);
                return response;
            })
            .catch(response => onHttpFailure(response));

        /**
         * Callback when http call failed
         * @param {Object} response
         * @returns {Promise}
         */
        function onHttpFailure(response) {
            // Set UI error
            const errorMessage = i18next.t('content-load-error');
            self.setShowcaseErrorMessage(errorMessage);

            // Only log when status is not 404 and request isn't cancelled
            if (!shouldIgnoreLoadFailure(response)) {
                self.eventLogger.notify(response, `Http Failure on endpoint /info`, { errorMessage });
            }

            // For 404 or 410 (org deleted) errors, pretend the catalog is revoked
            if ([404, 410].includes(response.status)) {
                return Promise.resolve(FALLBACK_RESPONSE_404);
            } else if (response.status === 500 || response.status === 503 || response.status === 504) {
                redirectToErrorPage(response.status);
            }

            return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + 'Http Failure on endpoint /info');
        }

        function redirectToErrorPage(errorCode) {
            window.location.href = `${env.baseUrl}/errors/${errorCode}.html`;
        }
    }

    /**
     * Handle info about the showcase and setup the shell layout
     * @param {Object} response
     */
    handle(response) {
        // Validation
        if (response.success === false) {
            // Set UI error
            const errorMessage = i18next.t('content-load-error');
            this.setShowcaseErrorMessage(errorMessage);

            // For 404 errors, pretend the catalog is revoked - for cancelled requests, ignore
            if (!shouldIgnoreLoadFailure(response)) {
                this.eventLogger.notify(errorMessage, 'Http failure', response);
            }

            return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + 'Http Failure');
        }

        this.info = response.data;

        if (response.data.available === false) {
            // Set UI error and log
            const errorMessage =
                response.data.creator && response.data.creator.email
                    ? i18next.t('content-expired-reach-out', { email: response.data.creator.email })
                    : i18next.t('content-expired');

            this.setShowcaseErrorMessage(errorMessage);

            this.header = this.createShowCaseHeader();
            this.renderShowCaseTheme();

            return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + 'Revoked share');
        }

        let result = Promise.resolve();

        this.modal = new Modal(this.domManager, this.eventLogger, this.eventBus, this.info.consent && this.info.consent.privacyPolicyUrl);

        this.render();
        this.addEventListeners();

        // Add a keep alive helper
        if (response.success) {
            this.keepAlive = new KeepAliveHelper(Url.getUrlWithParams('showcase-keep-alive'), DEFAULT_SESSION_TTL);
        }

        // Check if we need to authenticate to ADN
        if (response.success && response.data.hasOwnProperty('adn')) {
            result = authenticateWithAdn(response.data.adn, response.data.serverTime);
        }

        this.eventBus.on('track:downloadAll', () => {
            const items = this.info.content.items.filter(item => item.isDownloadable);
            this.eventLogger.trackItemsDownload(items);
        });

        return result;
    }

    render() {
        this.header = this.createShowCaseHeader();
        const consentRequired = this.info.consent && this.info.consent.required;
        const consentType = this.info.consent && this.info.consent.type;
        const consentEnabled = this.info.consent && this.info.consent.enabled;

        const showConsentModalPromise = ConsentModal.shouldShow(consentRequired, consentType, consentEnabled);
        const consentHiddenPromise = ConsentModal.consentHidden();

        Promise.all([showConsentModalPromise, consentHiddenPromise])
            .then(([showConsentModal, consentHidden]) => {
                if (showConsentModal) {
                    this.toggleShareButton(showConsentModal);

                    const container = this.domManager.get('body');
                    const privacyPolicyUrl = this.info.consent && this.info.consent.privacyPolicyUrl;

                    // Opens sidebar if there is more than 1 item in the share
                    const openSidebar = () => {
                        if (
                            this.showcaseViewer &&
                            this.showcaseViewer.sideBar &&
                            this.showcaseViewer.sideBar.data.itemCount > 1 &&
                            !Dom.isInResponsiveMobileMode()
                        ) {
                            this.showcaseViewer.sideBar.open();
                        }
                    };

                    const onViewContent = () => {
                        openSidebar();
                        this.consentDeferPromise.resolve();
                        this.trackShowcaseViewDeferPromise.resolve();
                    };

                    const onConsentAccepted = () => {
                        this.eventLogger.consentRequired = false;
                        this.eventLogger
                            .trackShowcaseView()
                            .finally(() => {
                                this.trackShowcaseViewDeferPromise.resolve();
                            })
                            .catch(() => {});

                        this.consentDeferPromise.resolve();
                        this.toggleShareButton(false);

                        openSidebar();

                        this.updatePolicyAcceptedAt().catch(error => {
                            if (shouldLogError(error)) {
                                this.eventLogger.notify(error, 'updatePolicyAcceptedAt failed');
                            }
                        });
                    };

                    this.consentModal = new ConsentModal(
                        container,
                        this.domManager,
                        this.eventBus,
                        consentType,
                        privacyPolicyUrl,
                        onConsentAccepted,
                        onViewContent,
                        this.info.consent && this.info.consent.expiryDays ? this.info.consent.expiryDays : DEFAULT_CONSENT_EXPIRY
                    );
                } else {
                    if (consentEnabled) {
                        if (consentRequired && consentHidden) {
                            this.toggleShareButton(true);
                        }
                        if (!consentRequired) {
                            this.updatePolicyAcceptedAt().catch(error => {
                                if (shouldLogError(error)) {
                                    this.eventLogger.notify(error, 'updatePolicyAcceptedAt failed');
                                }
                            });
                        }
                    }

                    this.consentDeferPromise.resolve();

                    setTimeout(() => {
                        // We don't want to track views made by Salesforce bots, so here we
                        // wait for 2 seconds before we start tracking. The idea behind this
                        // is that bots will view the page for less than that time, so their
                        // views will not be tracked.
                        this.eventLogger
                            .trackShowcaseView()
                            .finally(() => {
                                this.trackShowcaseViewDeferPromise.resolve();
                            })
                            .catch(() => {});
                    }, 2000);
                }
            })
            .catch(() => {
                throw new Error('Promise returned by ConsentModal.shouldShow threw an error');
            });

        const showPrivacyPolicy =
            (this.info.consent && this.info.consent.showPrivacyPolicy) || (!this.info.consent && this.info.showPrivacyPolicy);
        const privacyPolicyUrl = (this.info.consent && this.info.consent.privacyPolicyUrl) || 'http://www.showpad.com/privacy-policy';
        if (showPrivacyPolicy) {
            PrivacyWarning.shouldShow()
                .then(shouldShow => {
                    if (shouldShow) {
                        var privacyMessage = `By using this site you agree to our <a href="${privacyPolicyUrl}" target="_blank">Privacy Policy</a>`;
                        if (this.info.privacyPolicyContent) {
                            privacyMessage = this.info.privacyPolicyContent;
                        }
                        this.privacyWarning = this.createPrivacyWarning(privacyMessage);
                    }
                })
                .catch(() => {
                    throw new Error('Promise returned by PrivacyWarning.shouldShow() was rejected');
                });
        }

        this.renderShowCaseTheme();

        this.domManager.get('error-message').innerHTML = i18next.t('empty-message');
    }

    createShowCaseHeader() {
        const header = new Header(this.domManager, this.info, this.eventBus);
        header.onClickOpenModal(type => this.openModal(type));
        header.render();

        return header;
    }

    createPrivacyWarning(privacyContent) {
        const privacyWarning = new PrivacyWarning(this.domManager.get('privacy-warning'), this.domManager.get('body'), privacyContent);
        privacyWarning.render();

        return privacyWarning;
    }

    renderShowCaseTheme() {
        ShowcaseTheme.fromConfig(this.info.theme, this.info.organisation, this.domManager).render();
    }

    createShowCaseViewer() {
        const showcaseViewer = new ShowcaseViewer(
            this.domManager,
            this.eventLogger,
            this.eventBus,
            this.info,
            this.shareHash,
            this.consentDeferPromise
        );
        return showcaseViewer
            .load()
            .then(response => showcaseViewer.handle(response))
            .then(() => {
                this.showcaseViewer = showcaseViewer;
                const assetViewerItems = this.showcaseViewer.assetViewerItems;
                if (shouldRenderUrlPresentationInLargerIframe(assetViewerItems)) {
                    const css = `
                        .collection-content {
                            left: 0 !important;
                        }
                        .collection-sidebar-container {
                            display: none !important;
                        }
                        .vrow-content-container-url {
                            position: absolute !important;
                            top: 0 !important;
                            left: 0 !important;
                            bottom: 0 !important;
                            width: 100% !important;
                            height: auto !important;
                        }
                        @media all and (max-width: 520px), screen and (max-width: 736px) and (orientation: landscape) {
                            .collection-content {
                                top: 64px !important;
                            }
                        }
                    `;
                    const style = document.createElement('style');
                    document.head.appendChild(style);
                    style.type = 'text/css';
                    style.appendChild(document.createTextNode(css));
                }
                // redirect to url if only 1 url exists and allowRenderExternal === true
                if (shouldRedirectToUrl(assetViewerItems)) {
                    const presentation = assetViewerItems[0].presentations.find(presentation => presentation.type === 'url');
                    const url =
                        presentation && presentation.url
                            ? presentation.url
                            : presentation && presentation.urls
                            ? presentation.urls[0]
                            : null;
                    if (url) {
                        Promise.all([this.consentDeferPromise.promise, this.trackShowcaseViewDeferPromise.promise])
                            .then(() => this.eventLogger.createUrlAssetViewDeviceEvent(assetViewerItems[0]))
                            .then(() => window.location.replace(url))
                            .catch(() => {
                                throw new Error('Url asset redirect failed, one of the promises rejected');
                            });
                    }
                }

                const consentRequired = this.info.consent && this.info.consent.required;
                const consentType = this.info.consent && this.info.consent.type;

                ConsentModal.shouldShow(consentRequired, consentType)
                    .then(shouldShow => {
                        if (shouldShow) {
                            this.eventBus.dispatch('downloading-enabled', false);
                            this.openConsentModal();
                        } else {
                            this.eventBus.dispatch('downloading-enabled', true);
                        }
                    })
                    .catch(() => {
                        throw new Error('Promise returned by ConsentModal.shouldShow in createShowCaseViewer threw an error');
                    });
            });
    }

    addEventListeners() {
        this.onViewportChangeHeadroom();
        this.eventBus.on('modal:close', () => this.onCloseModal());
        this.eventBus.on('error:messagelog', (message, reason, error) => this.setShowcaseErrorMessageAndLog(message, reason, error));
        this.eventBus.on('error:message', message => this.setShowcaseErrorMessage(message));
    }

    onViewportChangeHeadroom() {
        const self = this;

        const MIN_SCROLL = 0;
        const MAX_SCROLL = 112;
        let lastY = 0;
        let distance = 0;

        this.eventBus.on('viewport:change', (offsetTop, scroll) => {
            const lastScrollY = scroll.lastY;
            const isDownScroll = scroll.down;
            let didScrollYChange = false;

            if (isDownScroll && distance < MAX_SCROLL && lastScrollY > MIN_SCROLL) {
                distance = distance + (offsetTop - lastY);
                distance = Math.min(distance, MAX_SCROLL);
                didScrollYChange = true;
            } else if (isDownScroll === false && distance > MIN_SCROLL) {
                distance = distance - (lastY - offsetTop);
                distance = Math.max(distance, MIN_SCROLL);
                didScrollYChange = true;
            }

            if (didScrollYChange === true) {
                window.requestAnimationFrame(() => moveHeaderFrame(distance));
            }

            lastY = offsetTop;
        });

        function moveHeaderFrame(distance) {
            self.domManager.get('showcase-header').style.transform = `translate3d(0, -${distance}px, 0)`;

            if (self.domManager.has('collection-sidebar-toggle')) {
                self.domManager.get('collection-sidebar-toggle').style.transform = `translate3d(0, -${distance}px, 0)`;
            }
        }
    }

    /**
     * Fetch (and cache) all elements from DOM
     */
    fetchDomElements() {
        // Shell
        this.domManager.set('title', document.querySelector('title'));
        this.domManager.set('body', document.querySelector('body'));
        this.domManager.set('overlay', document.querySelector('.overlay'));
        this.domManager.set('privacy-warning', document.querySelector('.privacy-warning-container'));
        this.domManager.set('error-message', document.querySelector('.empty-message'));

        // Header
        this.domManager.set('showcase-header', document.querySelector('.header'));
        this.domManager.set('company-logo', document.querySelector('.company-logo'));
        this.domManager.set('showcase-header-about-button', document.querySelector('.about-button'));
        this.domManager.set('showcase-header-share-button', document.querySelector('.share-button'));
        this.domManager.set('showcase-header-share-text', document.querySelector('.share-text'));

        // Viewer
        const sharedCollectionElement = document.querySelector('.shared-collection');
        const sharedCollectionContentElement = sharedCollectionElement.querySelector('.collection-content');
        this.domManager.set('shared-collection', sharedCollectionElement);
        this.domManager.set('collection-sidebar-container', sharedCollectionElement.querySelector('.collection-sidebar-container'));
        this.domManager.set('showcase-items-container', sharedCollectionContentElement.querySelector('.viewer-app-container'));
        this.domManager.set('shared-collection-content', sharedCollectionContentElement);

        // Modal
        this.domManager.set('company-description', Array.prototype.slice.call(document.querySelectorAll('.company-biography-content')));
        this.domManager.set('company-name', Array.prototype.slice.call(document.querySelectorAll('.company-name-content')));

        // Toast
        this.domManager.set('toast-container', document.querySelector('.toast-container'));
    }

    /**
     * Prepare urls that will be used to get/post data
     * @param {String} baseUrl
     * @param {String} shareHash
     */
    prepareDataUrls(baseUrl, shareHash) {
        const DEVICE_EVENT_ENDPOINT = `${baseUrl
            .split('/')
            .slice(0, -1)
            .join('/')}/deviceevent`;

        Url.setUrl('showcase-shallow-info', `${baseUrl}/share/${shareHash}/info`);
        Url.setUrl('showcase-info-with-svg', `${baseUrl}/share/${shareHash}/items/with-svg`);
        Url.setUrl('showcase-page-item', `${baseUrl}/share/${shareHash}/page/:pageId/items/:assetId`);

        Url.setUrl('showcase-keep-alive', `${baseUrl}/share/${shareHash}/keep-alive`);

        Url.setUrl('showcase-share', `${baseUrl}/share/${shareHash}/share`);

        Url.setUrl('showcase-privacy-policy', `${env.apiBase}/consent.json`);

        Url.setUrl('device-event-create', `${DEVICE_EVENT_ENDPOINT}/create`);
        Url.setUrl('device-event-update', `${DEVICE_EVENT_ENDPOINT}/update`);
        Url.setUrl('device-create', `${DEVICE_EVENT_ENDPOINT}/device/create/catalog/${shareHash}`);
        Url.setUrl('device-info', `${DEVICE_EVENT_ENDPOINT}/device`);

        Url.setUrl('pdf-merge-info', `${baseUrl}/share/${shareHash}/merge`);
        Url.setUrl('pdf-merge-download', `${baseUrl}/share/${shareHash}/download-pdf`);
        Url.setUrl('zip-download', `${baseUrl}/share/${shareHash}/download`);
    }

    /**
     * Set loading state
     * @param {boolean} isLoading
     */
    setIsShowcaseLoading(isLoading) {
        if (isLoading) {
            Dom.addClass('loading', document.querySelector('body'));
        } else {
            Dom.removeClass('loading', document.querySelector('body'));
        }
    }

    /**
     * Set the error message
     */
    setShowcaseErrorMessage(message) {
        this.domManager.get('error-message').innerHTML = message;
    }

    /**
     * Set an error message and log
     */
    setShowcaseErrorMessageAndLog(message, reason, error) {
        this.setShowcaseErrorMessage(message);
        this.eventLogger.notify(error, reason, { message });
    }

    /**
     * Set the error state
     * @param {boolean} isError
     */
    setShowcaseError(isError) {
        if (isError) {
            Dom.addClass('error', this.domManager.get('body'));
        } else {
            Dom.removeClass('error', this.domManager.get('body'));
        }
    }

    pauseAssetViewerLogger() {
        if (this.showcaseViewer && this.showcaseViewer.assetViewerLogger) {
            this.showcaseViewer.assetViewerLogger.canRefocus = false;
            this.showcaseViewer.assetViewerLogger.pause();
        }
    }

    resumeAssetViewerLogger() {
        if (this.showcaseViewer && this.showcaseViewer.assetViewerLogger) {
            this.showcaseViewer.assetViewerLogger.canRefocus = true;
            this.showcaseViewer.assetViewerLogger.resume();
        }
    }

    setShowcaseViewerPassive() {
        if (this.showcaseViewer && this.showcaseViewer.assetViewer) {
            this.pauseAssetViewerLogger();
            this.showcaseViewer.assetViewer.assetViewer.setPassive();
        }
    }

    setShowcaseViewerActive() {
        if (this.showcaseViewer && this.showcaseViewer.assetViewer) {
            this.resumeAssetViewerLogger();
            this.showcaseViewer.assetViewer.assetViewer.setActive();
        }
    }

    openConsentModal() {
        this.pauseAssetViewerLogger();
        this.consentModal.open();
    }

    /**
     * Open a specific modal
     * @param {String} type
     */
    openModal(type) {
        const data = {
            showcaseViewer: this.info
        };

        this.setShowcaseViewerPassive();

        this.modal.open(type, data);
    }

    onCloseModal() {
        if (!(this.consentModal && this.consentModal.isOpen)) {
            this.setShowcaseViewerActive();
            this.eventBus.dispatch('downloading-enabled', true);
        }
    }

    /**
     * Store the share hash in the document, when the user saves the share to its hard drive, we might be able to reload
     * it this way
     * @param {string} baseUrl
     * @param {string} shareHash
     */
    storeShareHash(baseUrl, shareHash, showcaseBaseUrl) {
        if (typeof shareHash === 'string' && typeof baseUrl === 'string') {
            const body = this.domManager.get('body');
            const firstElement = body.childNodes[0];

            const restoreScript = document.createElement('script');
            restoreScript.innerHTML = `window.shareHash = '${shareHash}'; window.shareBaseUrl = '${baseUrl}'; window.showcaseBaseUrl = '${showcaseBaseUrl}'`;

            body.insertBefore(restoreScript, firstElement);
        }
    }

    /**
     * Enable or disable Share button depending on consent is given or not
     * @param {boolen} shouldShow
     * @returns void
     */
    toggleShareButton(shouldShow) {
        const showcaseHeaderShareButton = this.domManager.get('showcase-header-share-button');

        if (shouldShow) {
            showcaseHeaderShareButton.setAttribute('disabled', 'disabled');
            Dom.addClass('disabled', showcaseHeaderShareButton);
        } else {
            showcaseHeaderShareButton.removeAttribute('disabled');
            Dom.removeClass('disabled', showcaseHeaderShareButton);
        }
    }

    updatePolicyAcceptedAt() {
        const url = Url.getUrlWithParams('showcase-privacy-policy');
        const options = {
            method: 'POST',
            credentials: 'include',
            body: `{ "share": "${window.shareHash}" }`
        };

        return Http.getJson(url, options);
    }
}
