import Url from '../helpers/url-helper';

const CONTENT_TYPE_JSON = 'application/json';

/**
 * Device helper class.
 * Provides static methods for creating device
 */
export default class Device {
    /**
     * Get the OS version
     * @returns {string}
     */
    static getUserOs() {
        const model = window.navigator.userAgent;
        let os = 'windows';

        if (model.toLowerCase().indexOf('linux') > -1) {
            os = 'linux';
        } else if (model.toLowerCase().indexOf('macintosh') > -1 || model.toLowerCase().indexOf('mac os') > -1) {
            os = 'osx';
        }

        return os;
    }

    /**
     * Creates a new device
     * @returns {Promise.<string>} The device id
     */
    static createNewDevice() {
        const data = {
            appDistributionType: 'release',
            appVersion: 1,
            appRevision: 0,
            model: window.navigator.userAgent,
            os: Device.getUserOs(),
            type: 'browser',
            app: 'scv'
        };

        const options = {
            method: 'post',
            credentials: 'include',
            headers: {
                Accept: CONTENT_TYPE_JSON,
                'Content-Type': CONTENT_TYPE_JSON
            },
            body: JSON.stringify(data)
        };

        return fetch(Url.getUrlWithParams('device-create'), options)
            .then(response => response.json())
            .then(response => {
                if (response.success === true && response.data) {
                    return response.data.id;
                } else {
                    throw new Error('invalid json response');
                }
            });
    }

    /**
     * Verify a device
     * @param {string} deviceId The device id
     * @returns {Promise.<boolean>} True if the device was created for 'scv'
     */
    static verifyDevice(deviceId) {
        return fetch(`${Url.getUrlWithParams('device-info')}/${deviceId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: CONTENT_TYPE_JSON
            }
        })
            .then(response => response.json())
            .then(response => {
                if (!response || response.success !== true || !response.data) {
                    throw new Error('invalid json response');
                }

                return response.data.app === 'scv' && response.data.id === deviceId;
            });
    }
}
