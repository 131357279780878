export default class Storage {
    /**
     * Store data by key
     * @param {String} key
     * @param {*} value
     * @returns {Promise}
     */
    static set(key, value) {
        return new Promise(storageExecutor);

        /**
         * Promise executor to store data in local storage
         * @param {function} resolve
         * @param {function} reject
         */
        function storageExecutor(resolve, reject) {
            try {
                window.localStorage.setItem(key, JSON.stringify(value));
                resolve();
            } catch (exception) {
                reject(exception);
            }
        }
    }

    /**
     * Get stored data by key
     * @param {String} key
     * @returns {Promise}
     */
    static get(key) {
        return new Promise(storageExecutor);

        /**
         * Promise executor to store data in local storage
         * @param {function} resolve
         * @param {function} reject
         */
        function storageExecutor(resolve, reject) {
            try {
                const data = JSON.parse(window.localStorage.getItem(key));
                resolve(data);
            } catch (exception) {
                reject(exception);
            }
        }
    }

    /**
     * Remove stored data by key
     * @param {String} key
     * @returns {Promise}
     */
    static remove(key) {
        return new Promise(storageExecutor);

        /**
         * Promise executor to remove data in local storage
         * @param {function} resolve
         * @param {function} reject
         */
        function storageExecutor(resolve, reject) {
            try {
                window.localStorage.removeItem(key);
                resolve();
            } catch (exception) {
                reject(exception);
            }
        }
    }
}
